import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEventNewWebHookForm',
  computed: {
    ...mapState('event', ['webhookSent'])
  }
})
export default class GtrEventEditWebHookForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Prop({ required: true, type: Object })
    webhook: object | undefined

    @Ref()
    readonly observerEditWebHoookForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        editWebHook: {
          name: null,
          url: null,
          uuid: null
        },
        table: {
          loading: false,
          headers: [
            { text: 'Time', align: 'start', sortable: false, value: 'created_at' },
            { text: 'Status', align: 'start', sortable: false, value: 'status' },
            { text: 'Url', align: 'start', sortable: false, value: 'url' },
            { text: 'Response Code', align: 'start', sortable: false, value: 'response_code' },
            { text: 'Action', sortable: false, searchable: false, value: 'action' }
          ],
          items: []
        },
        dialog: false
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('webhook', { immediate: true })
    onWebHookValueChange (payload: any) {
      if (payload) {
        this.$data.editWebHook.name = payload.name
        this.$data.editWebHook.url = payload.url
        this.$data.editWebHook.uuid = payload.uuid
      }
    }

    @Watch('webhookSent', { immediate: true })
    onWebHookSendValueChange (payload: any) {
      if (payload.data) {
        this.$data.table.items = payload.data
      }
    }

    async mounted () {
      if (this.$data.editWebHook) {
        this.$data.table.loading = true
        await this.$store.dispatch('event/loadWebhookSent', {
          webhook_uuid: this.$data.editWebHook.uuid,
          event_uuid: this.event_uuid
        })
        this.$data.table.loading = false
      }
    }

    async handleResend (item: any) {
      await this.$store.dispatch('event/resendWebhook', {
        webhook_uuid: this.$data.editWebHook.uuid,
        event_uuid: this.$route.params.event_uuid,
        webhook_sent_uuid: item.uuid
      })
      this.$data.dialog = false
    }

    handleCloseForm () {
      this.$data.editWebHook = {
        name: null,
        url: null,
        uuid: null
      }
      this.observerEditWebHoookForm.reset()
      this.$emit('close')
    }

    handleShowDetails (item: any) {
      this.$bus.$emit('gtr.level-two.webhook-details', item)
    }

    async submit () {
      const isValid = await this.observerEditWebHoookForm.validate()
      if (isValid) {
        try {
          this.$data.submitting = true
          const payload: any = {
            event_uuid: this.event_uuid,
            webhook_uuid: this.$data.editWebHook.uuid,
            data: {
              name: this.$data.editWebHook.name,
              url: this.$data.editWebHook.url
            }
          }
          await this.$store.dispatch('event/updateWebhook', payload)
          this.$bus.$emit('gtr.level-two.edit-webhook')
          Container.get(Notification).success('Webhook successfully updated.')
          this.handleCloseForm()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }
}
