import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrEventNewWebHookForm'
})
export default class GtrEventNewWebHookForm extends Vue {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: String })
    event_uuid: string | undefined

    @Ref()
    readonly observerNewWebHoookForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        newWebHook: {
          name: null,
          url: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    handleCloseForm () {
      this.$data.newWebHook = {
        name: null,
        url: null
      }
      this.observerNewWebHoookForm.reset()
      this.$emit('close')
    }

    async submit () {
      const form = (this.$refs.newWebHookForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            event_uuid: this.event_uuid,
            data: this.$data.newWebHook
          }
          await this.$store.dispatch('event/createWebhook', payload)
          this.handleCloseForm()
          this.$bus.$emit('gtr.level-two.new-webhook')
          Container.get(Notification).success('Webhook successfully created.')
        } catch (error) {

        } finally {
          this.$data.submitting = false
        }
      }
    }
}
