import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrEventNewWebHookForm from './forms/new/new.form.vue'
import GtrEventEditWebHookForm from './forms/edit/edit.form.vue'

@Component({
  name: 'GtrEventWebhooksView',
  computed: {
    ...mapState('event', ['webhooks'])
  },
  components: {
    'gtr-new-webhook-form': GtrEventNewWebHookForm,
    'gtr-edit-webhook-form': GtrEventEditWebHookForm
  }
})
export default class GtrEventWebhooksView extends Vue {
  data () {
    return {
      loading: false,
      table: {
        headers: [
          { text: 'Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Url', align: 'start', sortable: true, value: 'url' },
          { text: '', sortable: false, searchable: false, value: 'actions', width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewWebHookForm: false,
        showEditWebHookForm: false
      },
      webHookToDelete: null,
      webHookToEdit: null
    }
  }

  async mounted () {
    await this.fetchWebHooks()
  }

  created () {
    this.$bus.$on('gtr.level-two.new-webhook', this.fetchWebHooks)
    this.$bus.$on('gtr.level-two.edit-webhook', this.fetchWebHooks)
  }

  @Watch('webhooks', { immediate: true })
  onWebhooksChange (payload: any) {
    if (Array.isArray(payload)) {
      this.$data.table.items = payload
    }
  }

  handleShowNewWebHook () {
    this.$data.forms.showNewWebHookForm = true
  }

  handleCloseNewWebHook () {
    this.$data.forms.showNewWebHookForm = false
  }

  handleShowEditWebHook (payload: any) {
    this.$data.forms.showEditWebHookForm = true
    this.$data.webHookToEdit = payload
  }

  handleCloseEditWebHook () {
    this.$data.forms.showEditWebHookForm = false
    this.$data.webHookToEdit = null
  }

  handleShowDetailsWebHookForm () {
    this.$data.forms.showDetailsWebHookForm = true
  }

  handleDeleteWebHook (payload: any) {
    this.$data.webHookToDelete = payload
  }

  async onDeleteWebHookAction (payload: any) {
    if (payload.confirm) {
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        webhook_uuid: this.$data.webHookToDelete.uuid,
        data: {
          name: this.$data.webHookToDelete.name,
          url: this.$data.webHookToDelete.url
        }
      }
      await this.$store.dispatch('event/deleteWebhook', payload)
      Container.get(Notification).success('Webhook successfully deleted.')
      await this.fetchWebHooks()
    }
    this.$data.webHookToDelete = null
  }

  private async fetchWebHooks () {
    this.$data.loading = true
    await this.$store.dispatch('event/getWebhooks', {
      event_uuid: this.$route.params.event_uuid
    })
    this.$data.loading = false
  }
}
